import Script from 'next/script'

import initOneSegment, { cookieBannerSnippet } from '@finanzcheck/one-segment'
import { InitOptions } from '@finanzcheck/one-segment/dist/init'

import { getPageTrackingData } from '../../utils/segment'
import { getCookieBannerType } from '../../utils/cookies.utils'

interface OneSegmentHeadProps {
  pathname: string
}

export const OneSegmentHead = ({ pathname }: OneSegmentHeadProps) => (
  <script
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: cookieBannerSnippet(getCookieBannerType(pathname)),
    }}
  />
)

const initOneSegmentOptions: InitOptions = {
  debug: process.env.NEXT_PUBLIC_ENV === 'development',
  initialPageTrack: false,
  teamName: 'anchor',
  sessionCookieDomain: process.env.NEXT_PUBLIC_COOKIE_BASE_URL,
}
if (process.env.NEXT_PUBLIC_SEGMENT_HOST) {
  initOneSegmentOptions.segmentHost = process.env.NEXT_PUBLIC_SEGMENT_HOST
}

export const OneSegmentBody = () => (
  <Script
    strategy='lazyOnload'
    id='init-one-segment'
    src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    data-domain-script={process.env.NEXT_PUBLIC_ONE_TRUST_SCRIPT_ID}
    onLoad={() => {
      initOneSegment(
        process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        initOneSegmentOptions,
      )
      // eslint-disable-next-line no-undef
      window?.analytics?.page(getPageTrackingData())

      window?.addEventListener('message', (event) => {
        if (
          event?.data === 'CONSENT_CHANGED' &&
          event?.origin?.includes('smava.de')
        ) {
          window.Optanon.Close()
        }
      })
    }}
  />
)
