import { parse, serialize, CookieSerializeOptions } from 'cookie'

import { CookieBannerTypes } from '@finanzcheck/one-segment'

const cookieBaseUrl = process.env.NEXT_PUBLIC_COOKIE_BASE_URL

export const getCookie = (name: string): string | undefined => {
  if (!name) return
  if (typeof document !== 'undefined' && navigator && navigator.cookieEnabled) {
    const cookies = parse(document.cookie)
    if (cookies[name]) {
      // eslint-disable-next-line consistent-return
      return cookies[name]
    }
  }
}

export const setCookie = (
  cname: string,
  cvalue: string,
  options: CookieSerializeOptions = {},
) => {
  if (typeof document !== 'undefined' && navigator && navigator.cookieEnabled) {
    const defaultOptions = {
      maxAge: 365 * 24 * 60 * 60, // 356 days in seconds
      path: '/',
      domain: cookieBaseUrl || undefined,
    }

    const mergedOptions: CookieSerializeOptions = {
      ...defaultOptions,
      ...options,
    }

    document.cookie = serialize(cname, cvalue, mergedOptions)

    // if on localhost, also write the cookie on localhost to enable
    // hybrid work with shared plus localhost
    if (window.location.hostname === 'localhost') {
      const localMergedOptions = { ...mergedOptions }
      delete localMergedOptions.domain
      document.cookie = serialize(cname, cvalue, localMergedOptions)
    }
  }
}

export const getCookieBannerType = (path: string): string => {
  if (path?.includes('embed')) {
    return CookieBannerTypes.AFFILIATES
  }

  if (path?.includes('mobilede')) {
    return CookieBannerTypes.MOBLE_DE
  }

  return CookieBannerTypes.MAIN
}
