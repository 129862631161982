import { isSmava } from './getTenantInfo.utils'

function range(start: number, end: number, step: number) {
  let index = -1
  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0)
  const result = new Array(length)

  while (length--) {
    result[++index] = start
    start += step
  }
  return result
}
export function addDotInAmount(amount: number): string {
  return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
}

export function removeDotInAmount(amount: string | number): number | string {
  if (amount && typeof amount === 'string') {
    const number = Number(amount.split('.').join(''))
    if (!isNaN(number)) {
      return number
    }
  }
  return amount
}
export const NUM_OF_VISIBLE_OPTIONS = 5
export const MAX_CREDIT_AMOUNT = 120000
export const MIN_CREDIT_AMOUNT = isSmava ? 500 : 1000
export const MIN_TERM = 12
export const MAX_TERM = 120

export const TERM_OPTIONS = range(MIN_TERM, MAX_TERM + 1, 12)
export const AMOUNT_OPTIONS = range(MIN_CREDIT_AMOUNT, 15000 + 1, 250).concat(
  range(16000, MAX_CREDIT_AMOUNT + 1, 1000),
)

export const AMOUNT_OPTIONS_HIGH_RANGE = range(
  MIN_CREDIT_AMOUNT,
  15000 + 1,
  250,
)
  .concat(range(16000, MAX_CREDIT_AMOUNT + 1, 1000))
  .concat(range(130000, 400000 + 1, 10000))
  .concat(range(420000, 500000 + 1, 20000))
  .concat(range(550000, 600000 + 1, 50000))
  .concat(range(700000, 1000000 + 1, 100000))

/*
 * smava wording below
 */

export enum LABEL {
  Amount = 'Nettokreditbetrag',
  Purpose = 'Verwendung',
  Term = 'Kreditlaufzeit',
  InitialPayment = 'Anzahlung €',
  VehiclePrice = 'Kaufpreis €',
  'Purpose.REFINANCING' = 'Umschuldung',
  'Purpose.PRE_OWNED_CAR' = 'TR_KEY.PRE_OWNED_CAR',
  'Purpose.RENOVATION' = 'TR_KEY.RENOVATION',
  'Purpose.OTHER' = 'Freie Verwendung',
  'Purpose.NEW_CAR' = 'Neufahrzeug',
  'Purpose.FURNITURE' = 'Einrichtung/Möbel',
  'Purpose.HOLIDAY' = 'Urlaub',
  'Purpose.ENTERTAINMENT_ELECTRONICS' = 'PC/TV/Hifi/Video',
  'Purpose.ACCOUNT_BALANCE' = 'Ausgleich Girokonto',
  'Purpose.MOVE' = 'Umzug',
}

const translateLabel = (value: string) => {
  const PURPOSE_TEXT = {
    'TR_KEY.PRE_OWNED_CAR': isSmava ? 'Auto / Motorrad' : 'Gebrauchtfahrzeug',
    'TR_KEY.RENOVATION': isSmava ? 'Wohnen / Modernisierung' : 'Renovierung',
  }

  return value.includes('TR_KEY') ? PURPOSE_TEXT[value as keyof Object] : value
}

export enum PURPOSE {
  FREE_USE = 'OTHER',
  CAR = 'PRE_OWNED_CAR',
  LIVING = 'RENOVATION',
  RESCHEDULE = 'REFINANCING',
  // Additional purpose (categories) for FC
  NEW_CAR = 'NEW_CAR',
  FURNITURE = 'FURNITURE',
  ENTERTAINMENT_ELECTRONICS = 'ENTERTAINMENT_ELECTRONICS',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
  MOVE = 'MOVE',
}

export const DEFAULT_SELECTION_VALUES = Object.freeze({
  amount: 20000,
  term: 84,
  purpose: PURPOSE.FREE_USE,
  vehiclePrice: 20000,
  initialPayment: 0,
})

export const BANKLIST_DEFAULT_SELECTION_VALUES = Object.freeze({
  amount: 30000,
  term: 84,
  purpose: PURPOSE.FREE_USE,
})

export const SELECTION_FIELDS = Object.keys(DEFAULT_SELECTION_VALUES)

export const PURPOSE_OPTIONS = Object.keys(PURPOSE).reduce(
  (acc, key, index, purposeArray) => {
    if (index === 3 && isSmava) {
      purposeArray.splice(1)
    }

    const value = PURPOSE[key as keyof typeof PURPOSE]
    const labelKey: keyof typeof LABEL = `Purpose.${value}`
    const label = translateLabel(LABEL[labelKey]) as string
    if (label) {
      acc.push({
        value,
        label,
      })
    }
    return acc
  },
  [] as { value: PURPOSE; label: string }[],
)

// FC case - round up amount to nearest available on the amount list
export const adjustLoanAmount = (amount: number) => {
  if (!AMOUNT_OPTIONS.includes(amount)) {
    if (amount < MAX_CREDIT_AMOUNT) {
      // Round up to nearest
      const roundedUpAmount = AMOUNT_OPTIONS.filter((a) => a >= amount)[0]
      return roundedUpAmount
    }
    return MAX_CREDIT_AMOUNT
  }
  return amount
}

export const getAmountOptions = (minAmount: number, maxAmount: number) => {
  if (minAmount > 0 && maxAmount > 0) {
    return AMOUNT_OPTIONS.filter(
      (amountOption) => amountOption >= minAmount && amountOption <= maxAmount,
    )
  }

  if (minAmount > 0) {
    return AMOUNT_OPTIONS.filter((amountOption) => amountOption >= minAmount)
  }

  if (maxAmount > 0) {
    return AMOUNT_OPTIONS.filter((amountOption) => amountOption <= maxAmount)
  }

  return AMOUNT_OPTIONS
}
