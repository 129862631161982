/* eslint-disable no-undef */
import * as React from 'react'
import { useRouter } from 'next/router'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'

import { OneSegmentBody } from '../components/one-segment'
import { isSmava } from '../utils/getTenantInfo.utils'
import appInfo from '../package.json'
import { virtualPageTrackingHandler } from '../utils/segment'
import {
  getAdvertisementId,
  getRefId,
  getRegRoutePath,
  routerPushWithResumeToken,
} from '../utils/url'
import useUrlParams from '../hooks/useUrlParams'
import { setSessionId } from '../utils/session.utils'
import { GTM } from '../components/gtm'

declare global {
  interface Window {
    Optanon: {
      ToggleInfoDisplay: () => void
      Close: () => void
    }
    OneTrust: {
      OnConsentChanged: (event: unknown) => void
    }
    OnetrustActiveGroups: string
    analytics: {
      page: (properties: Record<string, unknown>) => void
      track: (
        eventName: string,
        properties: Record<string, unknown>,
        context?: Record<string, unknown>,
      ) => void
      identify: (properties: Record<string, unknown>) => void
    }
  }
}

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_ENV,
  enabledReleaseStages: ['production', 'stage', 'shared'],
  collectUserIp: false,
  appVersion: appInfo.version,
  onError(event) {
    // Supress unhandledrejection errors
    // Reasons:
    //  - rate limiting on bugsnag account (resulting on events being dropped)
    //  - lack of detailed and helpful information on error logs
    if (
      event?.errors &&
      event.errors[0]?.errorMessage?.includes(
        'unhandledrejection handler received a non-error.',
      )
    ) {
      return false
    }
  },
})

const bugsnagPlugin = Bugsnag.getPlugin('react')
const ErrorBoundary = bugsnagPlugin
  ? bugsnagPlugin.createErrorBoundary(React)
  : (React.createElement('div') as unknown as BugsnagErrorBoundary)

interface LandingPageProps {
  pageProps: Record<string, unknown>
  Component: React.ComponentType<unknown>
}

const LandingPage = ({ Component, pageProps }: LandingPageProps) => {
  const queryParams = useUrlParams()
  const router = useRouter()

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  React.useEffect(() => {
    /* eslint consistent-return: "warn" */
    if (isSmava) {
      window?.addEventListener('message', virtualPageTrackingHandler)

      return () =>
        window?.removeEventListener('message', virtualPageTrackingHandler)
    }
  }, [])

  React.useEffect(() => {
    setSessionId()

    try {
      const imodCurrentTraversalData = JSON.parse(
        localStorage.getItem('IMOD_CURRENT_TRAVERSAL_DATA') || '{}',
      )
      const previousAdvertisementId = imodCurrentTraversalData.advertisementId
      const currentAdvertisementId = getAdvertisementId(queryParams)

      if (
        (previousAdvertisementId &&
          previousAdvertisementId !== currentAdvertisementId) ||
        (queryParams?.paapiId && !queryParams?.resumeCleared)
      ) {
        localStorage.removeItem('IMOD_CURRENT_TRAVERSAL_DATA')
        // remove resume hash from url so as NOT to remove
        // IMOD_CURRENT_TRAVERSAL_DATA on reload
        window.location.hash = ''
        delete queryParams.resume

        router.push({
          query: { ...queryParams, resumeCleared: 1 },
        })
      }
    } catch (ex) {
      // THIRD_PARTY_COOKIE_BLOCKED
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const regroutePath = getRegRoutePath(router.pathname)
    if (queryParams.resume && !router.pathname.includes(regroutePath)) {
      routerPushWithResumeToken(router, {
        pathname: regroutePath,
        query: { ...queryParams },
      })
    }
  }, [])

  // ANCH-1602, ANCH-1595
  // Fix for attribution error for old legacy smava urls
  React.useEffect(() => {
    const { ref, refId } = queryParams
    if (ref && !refId) {
      router.push({
        query: {
          refId: getRefId(queryParams),
          ...queryParams,
        },
      })
    }
  })

  return (
    <ErrorBoundary>
      <GTM />
      <OneSegmentBody />
      <Component {...pageProps} />
    </ErrorBoundary>
  )
}

export default LandingPage
